@import 'colors';
@import 'effects';
@import 'fonts';
@import 'mixins';

$mat-snackbar-min-width: 240px;
$mat-snackbar-max-width: 600px;

$mat-dialog-padding: 39px;

$mat-select-panel-offset: 6px;
$mat-select-panel-min-width: 100%;

$mat-button-toggle-height: 44px;
$mat-button-toggle-min-width: 80px;

$mat-form-field-width: 320px;
$mat-form-field-height: 44px;
$mat-form-hint-height: 16px;
$mat-form-field-border-width: 1px;
$mat-form-field-hover-border-width: 1.5px;
$mat-form-field-focused-border-width: 2px;

$mat-checkbox-size: 16px;
$mat-checkbox-check-color: $neutral0;
$mat-checkbox-border-color: $neutral500;
$mat-checkbox-disabled-color: $neutral80;
$mat-checkbox-border-width: 1px;
$mat-checkbox-border-radius: 2px;

$mat-radio-button-size: 20px;
$mat-radio-button-input-size: 25px; // bigger input so that a user can click slightly off the radiobtn

// form field

.mat-form-field-appearance-outline {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-width: $mat-form-field-border-width !important;
    border-color: $neutral80 !important;
  }

  &:hover {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-width: $mat-form-field-hover-border-width !important;
    }

    .mdc-notched-outline__notch {
      border-left: $mat-form-field-hover-border-width solid transparent !important;
    }
  }

  .mdc-text-field {
    &--focused {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $yellow600 !important;
        border-width: $mat-form-field-focused-border-width !important;
      }

      .mdc-notched-outline__notch {
        border-left: 1px solid transparent !important;
      }
    }

    &--invalid {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $red700 !important;
        border-width: $mat-form-field-focused-border-width !important;
      }

      .mdc-notched-outline__notch {
        border-left: 1px solid transparent !important;
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    height: $mat-form-field-height;
  }

  .mat-mdc-form-field-infix {
    @include flexbox;
    min-height: $mat-form-field-height;
    height: $mat-form-field-height;
  }

  .mat-mdc-form-field-icon-suffix {
    color: $neutral500;
    padding-top: 1px;
  }

  .mdc-floating-label--float-above {
    top: 28px !important;
  }

  .mdc-floating-label:not(.mdc-floating-label--float-above) {
    position: absolute !important;
    transform: translate(12px, -15px) !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    max-height: $mat-form-hint-height;
    @include hint-input-error();

    .mat-mdc-form-field-error-wrapper {
      padding: 0 8px !important;
    }

    .mat-mdc-form-field-error {
      height: 13px;
      display: flex !important;
      align-items: center;
    }
  }
}

@mixin small-form-field($width: 24px) {
  width: $width;
  font-size: 1.5rem;

  ::ng-deep .mat-mdc-text-field-wrapper {
    padding: 0 $padding-xs;
  }

  ::ng-deep .mat-mdc-text-field-wrapper,
  ::ng-deep .mat-mdc-form-field-flex {
    height: 24px !important;
  }

  ::ng-deep .mat-mdc-form-field-infix {
    padding: $padding-xs 0;
    min-height: 24px;
    height: 24px;
  }

  ::ng-deep .mat-mdc-form-field-icon-suffix {
    margin-right: 4px;
  }

  ::ng-deep .mat-mdc-select {
    font-size: 1.5rem;
  }

  ::ng-deep .mat-mdc-select-arrow svg {
    height: 16px;
    width: 16px;
  }

  input {
    text-align: center;
  }
}

// textarea

@mixin textarea {
  ::ng-deep .mat-mdc-form-field-infix,
  ::ng-deep .mat-mdc-text-field-wrapper {
    height: auto;
  }

  textarea {
    padding: 0;
  }
}

// checkbox

.mat-mdc-checkbox {
  label {
    white-space: nowrap;
  }

  .mdc-checkbox__ripple {
    display: none;
  }

  .mdc-form-field .mdc-checkbox {
    padding: 0 !important;
    width: $mat-checkbox-size;
    height: $mat-checkbox-size;

    .mdc-checkbox__native-control {
      width: $mat-checkbox-size;
      height: $mat-checkbox-size;
    }

    .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
      border-color: $yellow600 !important;
      background-color: $yellow600 !important;
    }

    .mdc-checkbox__background {
      border: $mat-checkbox-border-width solid $mat-checkbox-border-color !important;
      background-color: $background !important;
      border-radius: $mat-checkbox-border-radius;
      width: $mat-checkbox-size;
      height: $mat-checkbox-size;
      inset: 0;

      svg {
        color: $mat-checkbox-check-color !important;
        width: 11px;
        height: 10px;
        top: 2px;
        left: 2px;
      }

      .mdc-checkbox__mixedmark {
        width: 8px;
        border-color: $mat-checkbox-check-color !important;
      }
    }
  }

  .mat-mdc-checkbox-touch-target {
    height: $mat-checkbox-size !important;
    width: $mat-checkbox-size !important;
  }

  &.mdc-checkbox--disabled {
    .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
      border-color: $mat-checkbox-disabled-color !important;
    }
  }

  &-checked:not(.mdc-checkbox--disabled) {
    .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
      border-color: $yellow600 !important;
      background-color: $yellow600 !important;
    }

    .mdc-form-field .mdc-checkbox .mdc-checkbox--selected {
      height: $mat-checkbox-size;
    }
  }

  &-checked.mdc-checkbox--disabled {
    .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
      border-color: $mat-checkbox-disabled-color !important;
      background-color: $mat-checkbox-disabled-color !important;
    }
  }
}

// snackbar

.mdc-snackbar__surface {
  border: 1px solid $background-dark !important;
  background: $background !important;
  box-shadow: none !important;
  min-width: $mat-snackbar-min-width !important;
  max-width: $mat-snackbar-max-width !important;

  p {
    color: $text-black;
  }
}

// tabs

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    border-bottom: 1px solid $neutral80;

    .mdc-tab {
      @include body;
      padding: 0 $padding-s;

      &:hover {
        background-color: $neutral50;
      }

      &.mdc-tab--active .mdc-tab__text-label {
        font-weight: bold;
      }

      .mdc-tab__text-label {
        font-size: 2rem;
        color: $text-black !important;
      }
    }

    .mat-ripple .mat-ripple-element {
      background-color: blue !important;
    }
  }

  .mat-mdc-tab-body-wrapper {
    overflow: visible !important;
  }

  .mat-mdc-tab-body {
    width: 100%;
    overflow: visible !important;
  }

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}

// stepper

.mat-stepper-horizontal {
  .mat-horizontal-stepper-header-container {
    border-bottom: 1px solid $neutral80;
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
  }

  .mat-step-header .mat-step-icon-selected {
    color: $text-white !important;
    background-color: $violet900 !important;
  }

  .mat-step-header .mat-step-icon:not(.mat-step-icon-selected) {
    color: $text-white;
    background-color: $background-dark;
  }
}

// slide toggle

.mat-mdc-slide-toggle {
  .mdc-switch__track {
    height: 16px !important;
    border-radius: 8px !important;

    &::after {
      background-color: $background-dark !important;
    }
  }

  .mdc-switch {
    width: 32px !important;

    .mdc-switch__handle-track .mdc-switch__handle {
      width: 12px !important;
      height: 12px !important;
      top: 8px;
      left: 2px;

      &::after {
        background-color: $neutral0 !important;
      }

      .mdc-switch__shadow,
      .mdc-switch__ripple,
      .mdc-switch__icons {
        display: none;
      }
    }
  }

  &.mat-mdc-slide-toggle-checked {
    .mdc-switch__track::after {
      background-color: $yellow600 !important;
    }

    .mdc-switch__handle-track .mdc-switch__handle {
      transform: translateX(4px) translateY(-6px);
    }
  }
}

// select panel (dropdown)

.mat-mdc-select-panel {
  margin-top: $mat-select-panel-offset !important;
  min-width: $mat-select-panel-min-width;
  box-shadow: $box-shadow-dropdown;
  padding: 0 !important;

  .mat-mdc-option {
    background-color: transparent;

    .mdc-list-item__primary-text {
      @include truncate-text($lines: 1);
    }

    .mat-pseudo-checkbox-minimal {
      display: none;
    }
  }

  .mat-mdc-option-multiple {
    &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: $text-black;
    }
  }
}

// modals

.no-padding .mat-mdc-dialog-container {
  padding: 0 !important;
  box-shadow: $box-shadow-modal !important;
}

// toggle button

@mixin toggle-button($background-color, $border-color: false, $font-weight: 400) {
  @include flexbox;
  background-color: $background-color;
  height: $mat-button-toggle-height;
  font-weight: $font-weight;
  width: fit-content;
}

.mat-button-toggle {
  @include toggle-button(
    $background-color: $color-toggle-button-default,
    $border-color: $color-toggle-button-border-default
  );

  &:hover {
    @include toggle-button(
      $background-color: $color-toggle-button-hover,
      $border-color: $color-toggle-button-border-hover
    );
  }

  &-disabled {
    pointer-events: none;
  }

  &-checked.mat-button-toggle-appearance-standard {
    @include toggle-button(
      $background-color: $color-toggle-button-active,
      $border-color: $color-toggle-button-border-active,
      $font-weight: 700
    );
  }

  .mat-button-toggle-label-content {
    padding: 0 $padding-s;
    min-width: $mat-button-toggle-min-width;
  }
}

// radio button

.mat-mdc-radio-button {
  .mdc-form-field .mdc-radio {
    .mat-mdc-radio-touch-target {
      height: $mat-radio-button-size;
      width: $mat-radio-button-size;
    }

    .mdc-radio__native-control {
      height: $mat-radio-button-input-size;
      width: $mat-radio-button-input-size;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// table

.mat-mdc-table {
  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      @include small-title;

      .mat-sort-header-arrow {
        color: $neutral400;
      }
    }
  }
}
