$yellow800: #7d5d0c;
$yellow700: #c79412;
$yellow600: #f9b917;
$yellow400: #fbce5d;
$yellow200: #fde3a2;
$yellow100: #fef1d1;
$yellow50: #fef8e8;

$violet900: #00052d;
$violet400: #4c506c;
$violet200: #999bab;
$violet100: #cccdd5;
$violet50: #e5e6ea;
$violet10: #fafafb;

$blue700: #457ea4;
$blue600: #569ecd;
$blue400: #89bbdc;
$blue300: #9ac5e1;
$blue100: #ddecf5;
$blue50: #eef5fa;

$red700: #b12b0a;
$red600: #dd360c;
$red400: #e77255;
$red50: #fcebe7;

$green800: #38684b;
$green600: #46b071;
$green400: #9addb6;
$green50: #f1faf5;

$orange600: #ff7d01;
$orange400: #ffa44d;

$purple600: #c163bc;
$purple400: #d492d0;

$neutral900: #222222;
$neutral500: #666666;
$neutral400: #9e9e9e;
$neutral300: #bdbdbd;
$neutral80: #e0e0e0;
$neutral70: #f0f0f0;
$neutral50: #f4f5f7;
$neutral20: #fafafa;
$neutral0: #ffffff;

$color-toggle-button-default: $neutral20;
$color-toggle-button-active: $neutral70;
$color-toggle-button-hover: $neutral50;

$color-toggle-button-border-default: #f2f2f2;
$color-toggle-button-border-active: $neutral80;
$color-toggle-button-border-hover: $neutral80;

$color-scrollbar-thumb: #7d7d7d;
$color-scrollbar-background: $neutral20;
$color-scrollbar-border: #ececec;

$linear-gradient-dark: linear-gradient(90deg, #f5b841 0%, #ec5729 100%);

$avatar-brown: #d08561;
$avatar-grapefruit: #e99478;
$avatar-red: #ec8b80;
$avatar-orange: #ecc180;
$avatar-yellow: #e9cd78;
$avatar-green: #87cd9b;
$avatar-dark-green: #93b69d;
$avatar-aqua: #9dd9d5;
$avatar-blue: #80bfec;
$avatar-violet: #7879e9;
$avatar-purple: #caa1ea;
$avatar-pink: #cd87a0;

$text-black: $neutral900;
$text-darker: $neutral500;
$text-dark: $neutral300;
$text-white: $neutral0;

$text-success: $green800;
$text-error: $red700;

$text-primary: $yellow600;
$text-secondary: $violet900;
$text-tertiary: $blue600;

$background: $neutral0;
$background-light: $neutral50;
$background-dark: $neutral80;
