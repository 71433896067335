@import "colors";
@import "spacing";
@import "fonts";

$border-radius: 6px;
$min-medium-button-width: 100px;
$min-small-button-width: 80px;
$content-margin: $margin-m 0 0;
$content-padding: $padding-l 0 0;

@mixin border-radius($radius: $border-radius) {
  border-radius: $radius;
}

@mixin content-margin {
  margin: $content-margin;
}

@mixin content-padding {
  padding: $content-padding;
}

@mixin outline {
  outline: 2px auto $yellow600;
}

@mixin json-column($width: 100px, $neutral0-space: nowrap) {
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: $neutral0-space;
  text-overflow: ellipsis;
  max-height: 50px;
  max-width: $width;
  cursor: pointer;
}

@mixin truncate-text($lines: 2, $overflow: ellipsis, $word-break: break-all) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow;
  word-break: $word-break;

  @supports (-webkit-line-clamp: $lines) {
    overflow: hidden;
    white-space: initial;
    text-overflow: $overflow;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  }
}

@mixin info-tooltip-wrapper($align: flex-start) {
  display: flex;
  align-items: $align;

  app-info-tooltip {
    display: block;
    margin-left: 4px;
  }
}

@mixin box-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 25%);
}

@mixin flexbox($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin grid($align: center, $justify: center) {
  display: grid;
  align-items: $align;
  justify-content: $justify;
}

@mixin remove-scrollbar() {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin dialog-scrollbar() {
  &::-webkit-scrollbar {
    width: 16px;
    border-right: 1px solid $color-scrollbar-border;
    border-left: 1px solid $color-scrollbar-border;
    background-color: $color-scrollbar-background;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-scrollbar-thumb;
    background-clip: content-box;
    border-radius: 8px;
    border: 3px solid transparent;
  }
}

@mixin button(
  $background-color,
  $background-hover-color,
  $box-shadow: false,
  $border-color: false,
  $size: "medium",
  $width: "wrap",
  $icon-font-size: $icon-size-l
) {
  padding: 0 $padding-s;
  background-color: $background-color;
  text-wrap: nowrap;

  @if $width == "full" {
    width: 100%;
  } @else {
    width: fit-content;
  }

  @if $size == "small" {
    min-width: $min-small-button-width;
    min-height: auto;
    font-size: 1.75rem;
  } @else {
    min-width: $min-medium-button-width;
    min-height: 44px;
  }

  @include border-radius;

  @if $background-color ==transparent {
    box-shadow: none;
  } @else {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 10%),
      inset 0 -1px 2px 0 rgba(0, 0, 0, 15%);
  }

  @if $border-color {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 10%),
      inset 0 -1px 2px 0 rgba(0, 0, 0, 15%), inset 0 0 0 1px $border-color;
  }

  mat-icon {
    font-size: $icon-font-size;
    width: $icon-font-size;
    height: $icon-font-size;

    &.left {
      order: 0;
      margin: 0 12px 0 0;
    }

    &.right {
      order: 1;
      margin: 0 0 0 12px;
    }
  }

  ::ng-deep .mdc-button__label {
    @include flexbox;
  }

  &:hover {
    background-color: $background-hover-color;
  }
}

@mixin deployment-backend-config() {
  width: 100%;

  a {
    @include hint-link;
  }

  mat-form-field {
    width: 320px;
  }

  app-deployment-option-checkbox {
    display: block;
    margin-top: $margin-s;
  }

  &__form-field-wrapper {
    position: relative;
    width: fit-content;
    margin-top: 5px;
  }

  &__options {
    margin-top: $margin-m;
  }

  &__info-text {
    margin-bottom: $margin-s;
  }

  &__resources {
    display: grid;
    align-items: center;
    grid-template-columns: 230px 230px 1fr;
  }

  &__resource-warning {
    margin: 1rem 0;
  }

  &__form {
    @include flexbox($align: flex-start, $justify: flex-start);
    flex-direction: column;
  }
}

// Auth related pages

@mixin auth-wrapper-class() {
  @include flexbox;
  background: $linear-gradient-dark;
  width: 100%;
  height: 100%;
}

@mixin auth-logo() {
  top: $logo-top;
  position: absolute;
  width: $logo-width;
}

@mixin auth-card {
  @include border-radius;
  width: $auth-card-width;
  background: $neutral0;
  padding: $padding-m;
}

@mixin sign-up-step() {
  width: 40px;
  height: 3px;
  border-radius: 20px;
  background-color: $yellow200;
  margin: 0 $margin-xs;

  &--done {
    background-color: $yellow600;
  }
}

// Integrations

@mixin integrations-grid() {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $margin-s;
  margin-top: $margin-s;
}

@mixin integrations-small-grid() {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $margin-s;
  margin-top: $margin-xs;
}

@mixin hor-nav-menu-item() {
  position: relative;
  @include border-radius;
  @include flexbox($justify: flex-start);
  background-color: unset;
  text-decoration: none;
  height: 40px;
  margin: $margin-xs $margin-xs $margin-xs 0;
  padding: $padding-xs;
  cursor: pointer;
}

// Table empty state

@mixin empty-state-table() {
  @include flexbox;
  flex-direction: column;
  width: 100%;
  margin: $margin-xxl 0;

  p {
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  p:first-of-type {
    @include small($color: $text-darker, $font-weight: 500);
  }

  p:last-of-type {
    @include small($color: $text-darker);
    margin-top: $margin-m;
  }
}
