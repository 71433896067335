:root {
  font-size: 8px;
}

$spacing-xxs: 0.5rem;
$spacing-xs: 1rem;
$spacing-s: 2rem;
$spacing-m: 3rem;
$spacing-l: 4rem;
$spacing-xl: 5rem;
$spacing-xxl: 6rem;

$icon-size-xl: 4rem;
$icon-size-l: 3rem;
$icon-size-m: 2.5rem;
$icon-size-s: 2.25rem;
$icon-size-xs: 2rem;
$icon-size-xxs: 1.5rem;

$z-index-minor: 1;
$z-index-fixed: 5;
$z-index-breadcrumb: 12;
$z-index-navigation: 15;
$z-index-toolbar: 20;
$z-index-overlay: 20;
$z-index-tooltip: 30;

$toolbar-height: 56px;
$avatar-size: 40px;

$auth-logo-top: 20px;
$auth-logo-width: 183px;
$auth-card-width: 480px;

$min-device-width: 768px;
$nav-breakpoint: 1200px;
$max-grid-width: 1180px;

$breakpoint-s: 768px;
$breakpoint-m: 1200px;
$breakpoint-l: 1440px;
$breakpoint-xl: 2000px;

// Spacing

$padding-xxs: $spacing-xxs;
$padding-xs: $spacing-xs;
$padding-s: $spacing-s;
$padding-m: $spacing-m;
$padding-l: $spacing-l;
$padding-xl: $spacing-xl;
$padding-xxl: $spacing-xxl;

$margin-xxs: $spacing-xxs;
$margin-xs: $spacing-xs;
$margin-s: $spacing-s;
$margin-m: $spacing-m;
$margin-l: $spacing-l;
$margin-xl: $spacing-xl;
$margin-xxl: $spacing-xxl;

@mixin body-grid() {
  display: grid;
  padding: 0 $padding-m $padding-xxl;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: $margin-s;
  max-width: $max-grid-width;
  margin: 0 auto;

  & ::ng-deep > * {
    grid-column: 1 / 13;
  }
}
