@use "@angular/material" as mat;

@import "./spacing";
@import "./fonts";
@import "./colors";
@import "./mixins";
@import "./effects";
@import "./material";

@include mat.core();

$mat-typography: mat.m2-define-typography-config(
  $font-family: (
    $font-family,
  ),
  $body-1: (
    font-size: 1.75rem,
  ),
  $body-2: (
    font-size: 1.75rem,
  ),
);

$light-theme: mat.m2-define-light-theme(
  (
    typography: $mat-typography,
    density: 0,
  )
);

@include mat.all-component-themes($light-theme);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline-color: $orange400;
}

body {
  color: $text-black;
  background-color: $background;

  @media (max-width: $min-device-width) {
    overflow: hidden;
  }
}

.cdk-overlay-container h1,
.header-1,
h1 {
  @include header-1;
}

.cdk-overlay-container h2,
.header-2,
h2 {
  @include header-2;
}

.cdk-overlay-container h3,
.header-3,
h3 {
  @include header-3;
}

body,
.body {
  @include body;
}

.cdk-overlay-container a,
a {
  @include link;
}

p.hint {
  font-style: italic;
  margin-bottom: 12px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family;
}

sup {
  font-size: 1rem;
  margin-left: 8px;
}

textarea {
  @include body;

  &:not(.mat-mdc-input-element):disabled {
    background-color: $neutral20;
  }
}

.backdrop {
  background: unset;
}

// input type number

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input {
  &::placeholder {
    color: $text-dark !important;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: $text-dark !important;
  }
}
