$font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

$line-height-2: 16px;
$line-height-4: 20px;
$line-height-6: 24px;
$line-height-8: 32px;

@mixin header-1($color: $text-black) {
  font-family: $font-family;
  font-weight: 500;
  font-size: 3rem;
  letter-spacing: 0.03em;
  line-height: $line-height-8;
  color: $color;
  margin-bottom: $margin-m;
}

@mixin header-2($color: $text-black) {
  font-family: $font-family;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: $line-height-6;
  color: $color;
  margin-bottom: $margin-s;
}

@mixin header-3($color: $text-black) {
  font-family: $font-family;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: $line-height-4;
  color: $color;
}

@mixin table-header($color: $text-black) {
  font-family: $font-family;
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: $line-height-2;
  color: $color;
}

@mixin subtitle($color: $text-black) {
  font-family: $font-family;
  font-size: 1.75rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: $line-height-4;
  color: $color;
}

@mixin body($color: $text-black, $font-weight: 400) {
  font-family: $font-family;
  font-size: 1.75rem;
  font-weight: $font-weight;
  letter-spacing: 0.05em;
  line-height: $line-height-4;
  color: $color;
}

@mixin small($color: $text-black, $font-weight: 400) {
  font-family: $font-family;
  font-size: 1.5rem;
  font-weight: $font-weight;
  letter-spacing: 0.02em;
  line-height: $line-height-2;
  color: $color;
}

@mixin button-text($color) {
  font-family: $font-family;
  font-size: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: $line-height-4;
  color: $color;
}

@mixin menu-label($color: $text-black) {
  font-family: $font-family;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: $line-height-4;
  color: $color;
}

@mixin active-menu-label($color: $text-black) {
  font-family: $font-family;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: $line-height-4;
  color: $color;
}

@mixin link($color: $text-tertiary, $font-size: 1.75rem) {
  @include header-3;
  font-size: $font-size;
  color: $color;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@mixin hint-link($color: $text-tertiary) {
  @include link($color);
  font-size: 10px;
  font-weight: 400;
}

@mixin hint-input-error($color: $text-darker) {
  color: $color;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.02em;
}

@mixin monospace-token-type {
  font-family: ui-monospace, Menlo, Consolas, Liberation Mono, monospace;
  letter-spacing: normal;
  font-size: 1.5rem;
  line-height: $line-height-2;
}

@mixin metric-card-title {
  font-family: $font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.015rem;
  color: $neutral500;
}

@mixin metric-card-metric {
  color: $neutral900;
  font-family: Helvetica Neue;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
}

@mixin small-title($color: $neutral400) {
  font-family: $font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  line-height: $line-height-2;
  color: $color;
}
